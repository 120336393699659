import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../css/employee/addNewEmployee.css";
import { getBranches } from "../../store/actionCreators/Branch/BranchAction";
import { addEmployees } from "../../store/actionCreators/Employees/EmployeeAction";
import Unauthorized from "../unauthorized";
import { useNavigate } from "react-router-dom";
const AddNewEmp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.role);
  const login = useSelector((state) => state.login);
  const branch = useSelector((state) => state.branch);
  const [selectedBranches, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const defaultState = {
    full_name: "",
    mobile_no: "",
    email: "",
    password: "",
    profile_pic:
      "https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_dark_1x_r2.png",
    date_of_birth: "",
    address: "",

    employee_role: "",
    employee_role_id: "",
    gender: "",
  };
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    let array = [];
    if (branch.data) {
      array = branch.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [branch]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const onStateChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
  };
  const submitForm = (e) => {
    if (
      selectedBranches.length !== 0 &&
      state.employee_role !== "" &&
      state.full_name !== "" &&
      state.password && state.date_of_birth!=""
    ) {
      let branch_id = "";

      for (let i = 0; i < selectedBranches.length; i++) {
        branch_id += `${selectedBranches[i].value},`;
      }
      if (branch_id[0] === "*") {
        branch_id = branch_id.slice(2, branch_id.length);
      }
      branch_id = branch_id.slice(0, branch_id.length - 1);
      addEmployees({ ...state, branch_id }).then((res) => {
        if (res.status === "success") {
          setTimeout(() => {
            setState(defaultState);
            setSelected([]);
          }, 3000);
        }
      });
      console.log({ ...state, branch_id });
    } else {
      toast.error(`Please fill the form completely`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    e.preventDefault();
  };
  useEffect(() => {
    dispatch(getBranches());
  }, []);

  // Roles Dropdown

  const displayRole = () => {
    return [
      { label: "manager", value: "manager" },
      { label: "branch head", value: "branch head" },
      { label: "region head", value: "region head" },
      { label: "barista", value: "barista" },
      { label: "kitchen", value: "kitchen" },
      { label: "server", value: "server" },
    ].map((item, index) => {
      return (
        <Dropdown.Item
          key={item.label}
          eventKey={`["${item.label}","${item.value}"]`}
        >
          {" "}
          {item.label}
        </Dropdown.Item>
      );
    });
  };
  const handleSelect = (e) => {
    const item = JSON.parse(e);
    setState({ ...state, employee_role: item[0], employee_role_id: item[1] });
  };

  if (props.editPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="row d-flex justify-content-center">
          <div className="form-container">
            <div className="form-head">Add New Employee</div>
            <div className="form-body">
              <form>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Full Name</label>
                      <input
                        value={state.full_name}
                        onChange={onStateChange("full_name")}
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Mobile Number</label>
                      <input
                        value={state.mobile_no}
                        onChange={onStateChange("mobile_no")}
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Email</label>
                      <input
                        value={state.email}
                        onChange={onStateChange("email")}
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Password</label>
                      <input
                        value={state.password}
                        onChange={onStateChange("password")}
                        type="password"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Date Of Birth</label>
                      <input
                        value={state.date_of_birth}
                        onChange={onStateChange("date_of_birth")}
                        type="date"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Status</label>
                      <select
                        onChange={onStateChange("status")}
                        className="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                      >
                        <option defaultValue={"Active"}>Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Branch</label>
                      <div className="d-flex">
                        {displayBranch()}
                        <span className="ms-3 p-2">{state.branch}</span>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Roles</label>
                      <div className="d-flex">
                        <DropdownButton
                          variant="light"
                          title="Roles"
                          id="dropdown-menu-align-right"
                          onSelect={handleSelect}
                        >
                          {displayRole()}
                        </DropdownButton>
                        <span className="ms-3 p-2">{state.employee_role}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Address</label>
                      <input
                        value={state.address}
                        onChange={onStateChange("address")}
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-5 p-2">
                      <label className="form-label">Gender</label>
                      <div className="d-flex">
                        <div className="form-check me-3">
                          <input
                            value={"male"}
                            onChange={onStateChange("gender")}
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="form-check ">
                          <input
                            value={"female"}
                            onChange={onStateChange("gender")}
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="p-2 mt-3">
                  <button className="btn btn-primary " onClick={submitForm}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    );
  else return <Unauthorized />;
};

export default AddNewEmp;
