import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { getAllCustomerRoles } from "../../store/actionCreators/Customers/CustomerRolesActions";
import {
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  DropdownButton,
  Container,
} from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Unauthorized from "../unauthorized";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import EditCustomerRoles from "./EditCustomerRoles";
import ViewCustomerRoles from "./ViewCustomerRoles";
import axios from "axios";

const AllCustomerRoles = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const customerRoleData = useSelector((state) => state.customer_roles);
  const Branches = useSelector((state) => state.getBranchInCat);
  const [customerRolesCount, setCustomerRolesCount] = useState([]);

  // console.log(customerRoleData, "roror");

  const sendGetRequest = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/fetch_customer_count_roles`
      );
      setCustomerRolesCount(resp.data);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(getAllCustomerRoles());
    sendGetRequest();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [currentCustomerType, setcurrentCustomerType] = useState({});

  console.log(props.editPermission, "editPermission");

  function rankFormatter(cell, row) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            onClick={() => {
              setcurrentCustomerType({ ...row });
              setShowModal(true);
            }}
            className="btn btn-sm btn-warning me-2"
          >
            Edit
          </Button>

          <Button
            onClick={() => {
              setShowViewModal(row);
              setcurrentCustomerType({ ...row });
            }}
            className="btn btn-sm btn-warning"
          >
            View
          </Button>
          <Button
            className="btn btn-sm btn-danger ms-2"
            // onClick={() => deleteFunction(row)}
          >
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          onClick={() => {
            setShowViewModal(row);
            setcurrentCustomerType({ ...row });
          }}
          className="btn btn-sm btn-warning"
        >
          View
        </Button>
      );
    }
  }

  const columns = [
    {
      dataField: "customer_type",
      text: "Customer Type",
      sort: true,
    },
    {
      dataField: "min_purchase",
      text: "Min Purchase",
      sort: true,
    },
    {
      dataField: "total_days",
      text: "Total Days",
      sort: true,
    },
    {
      dataField: "reduce_purchase",
      text: "Reduce Purchase",
      sort: true,
    },
    {
      dataField: "upg_purchase",
      text: "Upgrade Purchase",
      sort: true,
    },
    {
      dataField: "upg_days",
      text: "Upgrade Days",
      sort: true,
    },
    {
      dataField: "kitchen_disc_perc",
      text: "Kitchen Disc Percent",
      sort: true,
    },
    {
      dataField: "barista_disc_percent",
      text: "Barista Disc Percent",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "min_purchase",
      order: "asc",
    },
  ];
  const pageOptions = {
    sizePerPage: 10,
    totalSize: customerRoleData?.data
      ? customerRoleData.data?.all_customer_roles?.length
      : 0, // replace later with size(customers),
    custom: true,
  };

  console.log(customerRolesCount.data, "stuck");

  const main = () => {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <div className="page-content ">
            <form>
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <Card.Title className="h4 mb-2 ">
                        Customer Roles Datatable{" "}
                      </Card.Title>

                      <Row className="mb-2">
                        <Card
                          style={{
                            background: "transparent",
                            // width: "100%",
                            color: "#8d8d8d",
                            borderRight: "none",
                            borderLeft: "none",
                            borderRadius: "5px",
                          }}
                        >
                          <Card.Body>
                            <Row>
                              {customerRolesCount?.data
                                ? customerRolesCount?.data.map((i, index) => {
                                    return (
                                      <>
                                        <Row className="col-2">
                                          <Col key={index}>
                                            {/* <strong> CUSTOMER TYPE :</strong> */}
                                            <strong className="text-capitalize">
                                              {i?.customer_type}
                                            </strong>
                                            -
                                            <span className="text-capitalize">
                                              {i?.total_customers}
                                            </span>
                                          </Col>
                                        </Row>
                                      </>
                                    );
                                  })
                                : ""}
                            </Row>
                          </Card.Body>
                        </Card>
                      </Row>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="customer_type"
                        columns={columns}
                        data={customerRoleData.data?.customerRoleData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="customer_type"
                            columns={columns}
                            data={
                              customerRoleData.data
                                ? customerRoleData.data.all_customer_roles
                                  ? customerRoleData.data.all_customer_roles
                                  : []
                                : []
                            }
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="customer_type"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                        className="table-pagination"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
        </Row>
        <EditCustomerRoles
          show={showModal}
          close={() => setShowModal(false)}
          data={currentCustomerType}
        />
        <ViewCustomerRoles
          show={showViewModal}
          close={() => setShowViewModal(false)}
          data={currentCustomerType}
        />
      </Container>
    );
  };

  if (props.viewPermission) return <React.Fragment>{main()}</React.Fragment>;
  else return <Unauthorized />;
};

export default AllCustomerRoles;
