import React, { useState } from "react";
import {
  getAllBranchesInventory,
  getSoldProductReport,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import SoldProductReportTable from "./SoldProductReportTable";
import { toast } from "react-toastify";
import Unauthorized from "../../unauthorized";

const SoldProductReport = (props) => {
  const soldProductReportdata = useSelector(
    (state) => state.sold_product_report
  );
  const AllBranchList = useSelector((state) => state.get_all_branches);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate,setEndDate]=useState("");

  const [branchSelected, setBranchSelected] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [branchArr, setBranchArr] = useState([{}]);

  useEffect(() => {
    dispatch(getAllBranchesInventory());
  }, []);

  useEffect(() => {
    const array =
      AllBranchList?.data?.data?.map(({ branch_name, branch_id }) => ({
        label: branch_name,
        value: branch_id,
      })) ?? [];
    setBranchArr(array);
  }, [AllBranchList]);

  useEffect(() => {
    setDefaultData();
  }, [soldProductReportdata]);

  const setDefaultData = () => {
    if (AllBranchList.data && AllBranchList.data?.data) {
      const branchArrClone = AllBranchList?.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));

      setBranchArr(branchArrClone);
    }
  };

  const onShowReport = () => {
    if (!!startDate &&  !!endDate && !!branchSelected.value) {
      console.log(branchSelected.value);
      setShowReport(true);
      dispatch(getSoldProductReport(startDate,endDate, branchSelected.value));
    } else {
      toast.error("please select branch & valid date");
    }
  };

  if (props.viewPermission) {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div>
          {" "}
          <Row className="my-2 mb-5">
            <Col>
              <Card.Header>
                {" "}
                <strong>Report On: </strong> Stock detail by Product Options of
                orders
              </Card.Header>
            </Col>
            <Col xl="2" className="d-flex">
              <Button
                className="btn btn-warning my-auto"
                style={{ marginLeft: "auto" }}
                onClick={onShowReport}
              >
                Show Report
              </Button>
            </Col>
          </Row>
          <Row className="mt-4  mb-5">
            <Col>
              <Card>
                <Card.Header>Select Filters</Card.Header>
                <Card.Body>
                  <Form>
                    <Table style={{ width: "50%" }}>
                      <tbody>
                        <tr>
                          <td>Select Date from:</td>
                          <td className="py-2">
                            <Form.Control
                              type="date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Select Date to:</td>
                          <td className="py-2">
                            <Form.Control
                              type="date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>Branch:</td>
                          <td className="">
                            <Select
                              value={branchSelected}
                              onChange={(e) => setBranchSelected(e)}
                              options={branchArr}
                              placeholder="Select an option..."
                              styles={{
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "gray",
                                  fontStyle: "italic",
                                }),
                                menuList: (provided) => ({
                                  ...provided,
                                  zIndex: 999,
                                }),
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        {showReport && (
          <div>
           { console.log(soldProductReportdata?.data?.status)}
            {soldProductReportdata?.data?.status !== "success" ? (
              <strong>No Data Found</strong>
            ) : (
              <div>
                {(soldProductReportdata.data?.data || []).map((item, i) => {
                  return (
                    <SoldProductReportTable
                      key={i}
                      productReportdata={item}
                      addOnList={
                        soldProductReportdata?.data?.arr[i].add_ons_report || []
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default SoldProductReport;
