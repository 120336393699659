import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PCAPagination from "./PCAPagination";
import { CSVLink } from "react-csv";
import Async from "react-select/async";
import axios from "axios";
import {
  getCustomerWalletBalanceSummary,
  setPCOLPagination,
} from "./../../../store/actionCreators/Reports/CustomerReportAction";
import ScrollButton from "../../scrolltotop/ScrollButton";
import { toast } from "react-toastify";
import Loader from "../../Loader/LoaderTable";

const CustomerWalletBalanceSummary = (props) => {
  const dispatch = useDispatch();
  const [customerSelected, setCustomerSelected] = useState({});
  const branch = useSelector((state) => state.branch);
  const page = useSelector((state) => state.pcol_page);
  const cwbReport = useSelector(
    (state) => state.customer_wallet_balance_summary
  );
  // const pcol = useSelector((state) => state.per_customer_orderlist);
  const [records, setRecords] = useState(100);

  const [isDataFetched, setisDataFetched] = useState(false);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [custSelected, setCustSelected] = useState({
    label: "All",
    value: "All",
  });

  const [query, setQuery] = useState({
    customer_no: "All",
    branch_id: "All",
    add_from: "All",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  useEffect(() => {
    if (cwbReport.data && cwbReport.data.status === "success") {
      setisDataFetched(true);
      const productDataClone = cwbReport.data.data.map((e) => {
        return {
          ...e,
          customer_name: e.customer?.first_name,
          branch_name: e.branch_name,
          customer_no: e.customer_no,
          createdAt: new Date(e.createdAt).toLocaleString(),
          wallet_balance:
            Number.parseFloat(
              Math.round(e.wallet_balance * 100) / 100
            ).toLocaleString() || 0,
          memb_upg_amount:
            Number.parseFloat(
              Math.round(e.memb_upg_amount * 100) / 100
            ).toLocaleString() || 0,
          memb_reduce_amount:
            Number.parseFloat(
              Math.round(e.memb_reduce_amount * 100) / 100
            ).toLocaleString() || 0,
          total_spend:
            Number.parseFloat(
              Math.round(e.total_spend * 100) / 100
            ).toLocaleString() || 0,
        };
      });
      setProductData(productDataClone);
    } else if (cwbReport?.data?.status === "failure") {
      setProductData([]);
      setisDataFetched(true);
    }
  }, [cwbReport]);

  // useEffect(() => {
  //   if (cwbReport.data && cwbReport.data.status === "success") {
  //     const customerDataClone = cwbReport.data.data;
  //     let popular_items = "";
  //     cwbReport.data.popular_items.forEach((pi) => {
  //       popular_items += `${pi.product_list.product_name}, `;
  //     });
  //     customerDataClone.popular_items = popular_items;
  //     setCustomerData(customerDataClone);
  //   }
  // }, [cwbReport]);

  // const joinItems = (items) => {
  //   let oi = "";
  //   items.forEach((i) => {
  //     oi += `${i.product_name}, `;
  //   });
  //   return oi;
  // };
  // useEffect(() => {
  //   if (pcol.data && pcol.data.status === "success") {
  //     const productDataClone = pcol.data.data.map((r) => {
  //       return {
  //         ...r,
  //         items: joinItems(r.order_items),
  //         createdAt: new Date(r.createdAt).toLocaleString(),
  //       };
  //     });
  //     setProductData(productDataClone);
  //   } else setProductData([]);
  // }, [pcol]);

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };

  const loadData = async (customer) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_cust?search=${customer}`
      );

      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
            c.mobile_no
          })`,
          value: c.mobile_no,
          customer_id: c.customer_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  useEffect(() => {
    dispatch(getCustomerWalletBalanceSummary(query, page, records));
  }, [page]);

  const onShowReport = () => {
    setProductData([]);
    // setRecordPage(1);
    const queryClone = { ...query };
    queryClone.customer_no = custSelected.value;
    queryClone.branch_id = branchSelected.value;

    dispatch(getCustomerWalletBalanceSummary(queryClone, page, records));
    setShowReportClicked(true);
  };

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;
  queryClone.customer_no = custSelected.value;

  const exportCWBSReport = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_cust_wallet_balance_report`,
        queryClone
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "mobile_no",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "wallet_balance",
      text: "Wallet Balance",
      sort: true,
    },
    {
      dataField: "customer_type",
      text: "Customer Category",
      sort: true,
    },
    {
      text: "Membership Retention Amount",
      dataField: "memb_reduce_amount",
      sort: true,
    },
    {
      text: "Membership Upgrade Amount",
      dataField: "memb_upg_amount",
      sort: true,
    },
    {
      dataField: "memb_days_left",
      text: "Membership Days Left",
      sort: true,
    },
    {
      dataField: "memb_upg_categ",
      text: "Membership Upgrade Category",
      sort: true,
    },
    {
      dataField: "branch",
      text: "Branch Name",
      sort: true,
    },
    {
      dataField: "total_spend",
      text: "Life Time Spend",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "add_from",
      text: "Add From",
      sort: true,
    },
  
  ];

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: records,
    totalSize: cwbReport.data?.total_count, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  // const customerStatusOptions = [
  //   { label: "All", value: "All" },
  //   { label: "General", value: "General" },
  //   { label: "Silver", value: "Silver" },
  //   { label: "Gold", value: "Gold" },
  //   { label: "Platinum", value: "Platinum" },
  // ];
  const creditFromOptions = [
    { label: "All", value: "All" },

    { label: "APP", value: "APP" },
    { label: "WEB", value: "WEB" },
    { label: "POS", value: "POS" },
    { label: "Admin", value: "Admin" },
  ];

  return (
    // <Container
    //   fluid
    //   className={props.sideToggle === true ? "closeDash" : "openDash"}
    // style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    // >
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">CUSTOMER WALLET BALANCE SUMMARY</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table>
                  <tbody>
                    <tr>
                      <td>Customer No:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "", value: "" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={custSelected}
                          onChange={(e) => setCustSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Add From:</td>
                      <td className="">
                        <Select
                          options={creditFromOptions}
                          value={{
                            label: query.add_from,
                            value: query.add_from,
                          }}
                          onChange={(e) =>
                            setQuery({ ...query, add_from: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Customer:</td>
                      <td className="">
                        <Async
                          defaultOptions={[]}
                          cacheOptions={[]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={customerSelected}
                          onChange={(e) => setCustomerSelected(e)}
                        />
                      </td>
                      <td>Customer Type:</td>
                      <td className="">
                        <Select
                          options={customerStatusOptions}
                          value={customerType}
                          onChange={(e) => setCustomertype(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Card>
            <Card.Header>Customer Data</Card.Header>
            <Card.Body>
              <Table style={{}}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Name:</strong>
                    </td>
                    <td>{customerData.first_name}</td>

                    <td>
                      <strong>Total Spend:</strong>
                    </td>
                    <td>{customerData.total_spend}</td>

                    <td>
                      <strong>Mobile No.:</strong>
                    </td>
                    <td>{customerData.mobile_no}</td>

                    <td>
                      <strong>Email:</strong>
                    </td>
                    <td>{customerData.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Date of birth:</strong>
                    </td>
                    <td>{customerData.date_of_birth}</td>

                    <td>
                      <strong>Gender:</strong>
                    </td>
                    <td>{customerData.gender}</td>

                    <td>
                      <strong>Branch:</strong>
                    </td>
                    <td>{customerData.branch}</td>

                    <td>
                      <strong>Customer Type:</strong>
                    </td>
                    <td>{customerData.customer_type}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Wallet Balance:</strong>
                    </td>
                    <td>{customerData.wallet_balance}</td>

                    <td>
                      <strong>Total Revenue:</strong>
                    </td>
                    <td>{cwbReport.data?.total_revenue}</td>

                    <td>
                      <strong>Popular items:</strong>
                    </td>
                    <td>{customerData.popular_items}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportCWBSReport();
                }}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {!productData.length > 0 &&
                                  cwbReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {cwbReport?.data?.status === "failure" &&
                                    !cwbReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <PCAPagination
                                  pageNum={Math.ceil(
                                    cwbReport.data
                                      ? cwbReport.data.total_count / records
                                      : 1
                                  )}
                                />
                              </Col>
                              <Col className="d-flex">
                                <div style={{ marginLeft: "auto" }}>
                                  <Select
                                    options={[
                                      { label: "100", value: "100" },
                                      { label: "500", value: "500" },
                                      { label: "1000", value: "1000" },
                                    ]}
                                    onChange={(e) => {
                                      dispatch(setPCOLPagination(1));
                                      setProductData([]);
                                      dispatch(
                                        getCustomerWalletBalanceSummary(
                                          {
                                            ...queryClone,
                                          },
                                          page,
                                          e.value
                                        )
                                      );
                                      setRecords(e.value);
                                    }}
                                    className="ml-auto"
                                    value={{ label: records, value: records }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default CustomerWalletBalanceSummary;
