import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import MOPPagination from "./MOPPagination";
import { CSVLink } from "react-csv";
import Async from "react-select/async";
import axios from "axios";
import ScrollButton from "../../scrolltotop/ScrollButton";
import { getItemwiseSalesSummary } from "../../../store/actionCreators/Reports/OrderReportsAction";
import Loader from "../../Loader/LoaderTable";
import { toast } from "react-toastify";

const ItemwiseSalesSummary = (props) => {
  const dispatch = useDispatch();
  const iwssReport = useSelector((state) => state.itemwise_sales_summary);
  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [page, setPage] = useState(1);
  const [branchArr, setBranchArr] = useState({
    label: "",
    value: "",
  });
  const [branchSelected, setBranchSelected] = useState({
    label: branchArr[0]?.label,
    value: branchArr[0]?.value,
  });

  const [empSelected, setEmpSelected] = useState({
    label: "All",
    value: "All",
  });
  const [employeeSelected, setEmployeeSelected] = useState({
    label: "All",
    value: "All",
  });

  const [showReportClicked, setShowReportClicked] = useState(false);

  const [query, setQuery] = useState({
    product_list_id: empSelected.value,
    branch_id: branchSelected?.value,
    // employee_id: "All",
    order_from: "All",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  useEffect(() => {
    if (iwssReport.data && iwssReport.data.status === "success") {
      setisDataFetched(true);
      const productDataClone = iwssReport.data.data.map((e) => {
        return {
          ...e,
          total_price:
            Number.parseFloat(
              Math.round(e.total_price * 100) / 100
            ).toLocaleString() || 0,
        };
      });
      // productDataClone.push(total);
      setProductData(productDataClone);
      setPage(1);
    } else if (iwssReport.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [iwssReport]);

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_product?search=${emp}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((e) => ({
          label: `${e.product_name}`,
          value: e?.product_list_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };
  const loadDataEmp = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.full_name}`,
          value: e.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      // branchArrClone.unshift({
      //   label: branchArrClone[0].branch_name,
      //   value: branchArrClone[0].branch_id,
      // });

      setBranchArr(branchArrClone);
      setBranchSelected(branchArrClone[0]);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.product_list_id = empSelected.value;
    // queryClone.employee_id = employeeSelected.value;
    dispatch(getItemwiseSalesSummary(queryClone, page));
    setShowReportClicked(true);
  };

  const queryClone = { ...query };
  queryClone.branch_id = branchSelected.value;
  queryClone.product_list_id = empSelected.value;

  const exportIWSSCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_itemswise_sales`,
        queryClone
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  const columns = [
    // {
    //   dataField: "branch",
    //   text: "Branch",
    //   sort: true,
    // },
    {
      dataField: "product_name",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
    },
    {
      dataField: "total_price",
      text: "Product Price",
      sort: true,
    },
    // {
    //   dataField: "item",
    //   text: "Item",
    //   sort: true,
    // },

    // {
    //   dataField: "order_from",
    //   text: "Order From",
    //   sort: true,
    // },
    // {
    //   dataField: "revenue",
    //   text: "Revenue",
    //   sort: true,
    // },
    // {
    //   dataField: "sales",
    //   text: "Sales",
    //   sort: true,
    // },
  ];

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: productData?.length,
    totalSize: productData?.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;
  // const loadDataemplo = () => {
  //   return (
  //     !!employees.data?.data &&
  //     employees?.data?.data.map((item, index) => {
  //       return {
  //         label: item.full_name,
  //         value: item.employee_id,
  //         branch_id: item.branch_id,
  //         employee_id: item.employee_id,
  //       };
  //     })
  //   );
  // };

  const orderFromOptions = [
    { label: "All", value: "All" },
    { label: "WEBPOS", value: "WebPos" },
    { label: "WEBSITE", value: "Website" },
    { label: "APP", value: "App" },
  ];
  return (
    // <Container
    //   fluid
    //   className={props.sideToggle === true ? "closeDash" : "openDash"}
    // style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    // >
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto text-capitalized">
            ITEMWISE SALES SUMMARY (CONSOLIDATED)
          </h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadDataEmp(e)}
                          value={employeeSelected}
                          onChange={(e) => setEmployeeSelected(e)}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>Order From</td>
                      <td className="">
                        <Select
                          options={orderFromOptions}
                          value={{
                            label: query.order_from,
                            value: query.order_from,
                          }}
                          onChange={(e) =>
                            setQuery({ ...query, order_from: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Product:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Category:</td>
                      <td className="">
                        <Select
                          options={categoryOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Item:</td>
                      <td className="">
                        <Select
                          options={categoryOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    {/* 
                    <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportIWSSCSV();
                }}
              >
                {/* <CSVLink
                  // data={productData.map((p) => {
                  //   if (!p.not_include)
                  //     return {
                  //       Staff: p.full_name,

                  //       Sales_Total: p.paid_price,

                  //       Order_Count: p.order_count,
                  //     };
                  //   else
                  //     return {
                  //       Staff: "Total",
                  //       Sales_Total: sbsReport?.data?.total_sales,
                  //       Order_Count: sbsReport?.data?.total_order_count,
                  //     };
                  // })}
                  data={[]}
                  filename={"report.csv"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Export
                </CSVLink> */}
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="product_list_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="product_list_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="product_list_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {!productData.length > 0 &&
                                  iwssReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {iwssReport?.data?.status === "failure" &&
                                    !iwssReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                  {/* {!isDataFetched && <Loader />}
                                  {productData?.length === 0 &&
                                    iwssReport.data?.status !== "success" &&
                                    isDataFetched &&
                                    "No data found"} */}
                                </div>
                              </Col>
                            </Row>

                            {/* <Row>
                              <MOPPagination pageNum={Math.ceil(page)} />
                            </Row> */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default ItemwiseSalesSummary;
