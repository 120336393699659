import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderList,
  setOrderPagination,
} from "./../../store/actionCreators/Orders/OrdersAction";
import OrderPagination from "./OrderPagination";
import { Link } from "react-router-dom";
import CustomerList from "./CustomerList";
import AddCustomerToModal from "./AddCustomerToGroupModal";
import Loader from "../Loader/LoaderTable";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import dateFormat, { masks } from "dateformat";
import Unauthorized from "../unauthorized";

const AllOrders = (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const filtered_customers = useSelector((state) => state.filtered_customers);
  const dashboard_filters = useSelector((state) => state.dashboard_filters);

  const branch = useSelector((state) => state.branch);
  const [orderListVisible, setOrderListVisible] = useState(true);

  const [displayableOrders, setDisplayableOrders] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [filters, setFilters] = useState({
    branch_id: "All",
    order_id: "",
    coupon: "",
    order_type: "",
    payment_method: "",
    max_price: "",
    min_price: "",
    start_date: "",
    end_date: "",
    customer_no: "",
    product_name: "",
  });

  const [franchiseOptions, setFranchiseOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [currbranch, setCurrBranch] = useState({});
  const [currfranchise, setCurrFran] = useState({
    franchise_id: "",
    franchise_name: "",
  });
  const [addCustomerToGrpModal, setAddCustomerToGrpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState("");
  const [records, setRecords] = useState(10);

  useEffect(() => {
    setPageNo(1);
    setDefaultBranchFranchise();
    // dispatch(fetchCustomerList(1, getFilterQuery()));
  }, []);

  useEffect(() => {
    setLoading(false);
    setDisplayOrders();
  }, [orders]);

  useEffect(() => {
    const queryString = getFilterQuery();
    dispatch(fetchOrderList(pageNo, queryString, records));
  }, [franchiseOptions, records]);

  const getFilterQuery = () => {
    let qs = "";
    // if (currbranch.branch_id) {
    //   qs += "&";
    //   qs += `branch_id=${currbranch.branch_id}`;
    // }
    if (currfranchise.franchise_id) {
      qs += "&";
      qs += `franchise_id=${currfranchise.franchise_id}`;
    }
    if (filters.branch_id !== "") {
      qs += "&";
      qs += `branch_id=${filters.branch_id}`;
    }
    if (filters.order_id !== "") {
      qs += "&";
      qs += `order_id=${filters.order_id}`;
    }
    if (filters.coupon !== "") {
      qs += "&";
      qs += `coupon=${filters.coupon}`;
    }
    if (filters.order_type !== "") {
      qs += "&";
      qs += `order_type=${filters.order_type}`;
    }
    if (filters.payment_method !== "") {
      qs += "&";
      qs += `payment_method=${filters.payment_method}`;
    }
    if (filters.max_price !== "") {
      qs += "&";
      qs += `max_price=${filters.max_price}`;
    }
    if (filters.min_price !== "") {
      qs += "&";
      qs += `min_price=${filters.min_price}`;
    }
    if (filters.start_date !== "") {
      qs += "&";
      qs += `start_date=${filters.start_date}`;
    }
    if (filters.end_date !== "") {
      qs += "&";
      qs += `end_date=${filters.end_date}`;
    }
    if (filters.customer_no !== "") {
      qs += "&";
      qs += `customer_no=${filters.customer_no}`;
    }
    if (filters.product_name !== "") {
      qs += "&";
      qs += `product_name=${filters.product_name}`;
    }

    qs = qs.substring(1);
    return qs;
  };

  const setDefaultBranchFranchise = () => {
    if (dashboard_filters.data) {
      setFranchiseOptions(dashboard_filters.data.data);
      setCurrFran(dashboard_filters.data.data[0] || null);
      setBranchOptions(dashboard_filters.data.data[0]?.branches || null);
      setCurrBranch(dashboard_filters.data.data[0]?.branches[0] || null);
    }
  };

  const setDisplayOrders = () => {
    if (orders.data && orders?.data?.status === "success") {
      const displayableOrdersClone = orders.data.data.map((o) => {
        let order_items = "";
        o.order_items.forEach((oi) => {
          order_items += `${oi.product_name}, `;
        });

        let applied_coupons = "";
        if (
          typeof o.applied_coupons === "object" &&
          o.applied_coupons !== null
        ) {
          applied_coupons = o.applied_coupons.coupon;
        }

        return {
          ...o,
          order_items,
          applied_coupons,
          createdAt: `${dateFormat(new Date(o.createdAt))}`,
        };
      });

      setDisplayableOrders(displayableOrdersClone);
      console.log(displayableOrdersClone);
    } else {
      setDisplayableOrders([]);
    }
  };

  
  function rankFormatter(cell, row, rowIndex, formatExtraData) 
  {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`/orders/viewOrder/${row.order_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`/orders/editOrder/${row.order_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`/orders/viewOrder/${row.order_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }
  async function handleSubmit(event) {
    event.preventDefault();
  }

  const [branchName, setBranchName] = useState("All");
  const [ordertype, setOrderType] = useState("All");

  const onChangeForSearchFields = (field, value) => {
    if (field === "branch_id") {
      setBranchName(value);
      value = value.value;
    }
    if (field === "order_type") {
      console.log("here in order type");
      setOrderType(value);
      value = value.value;
    }
    console.log("value here", value);
    const filters_clone = { ...filters };
    filters_clone[field] = value;
    setFilters(filters_clone);
    
  };
  useEffect(() => {
    const getData = setTimeout(() => {
      showFilteredOrdersBySearch(1) }, 100);
    return () => {
      clearTimeout(getData);
    };
  }, [filters]);
  const displayBranches = () => {
    return branchOptions.map((item, index) => {
      return (
        <Dropdown.Item
          key={item.branch_id}
          eventKey={`["${item.branch_name}","${item.branch_id}"]`}
        >
          {" "}
          {item.branch_name}
        </Dropdown.Item>
      );
    });
  };

  const handleSelectB = (e) => {
    const item = JSON.parse(e);
    setCurrBranch({ branch_id: item[1], branch_name: item[0] });
  };

  const displayFranchises = () => {
    return franchiseOptions.map((item, index) => {
      return (
        <Dropdown.Item
          key={item.franchise_id}
          eventKey={`["${item.franchise_name}","${item.franchise_id}"]`}
        >
          {" "}
          {item.franchise_name}
        </Dropdown.Item>
      );
    });
  };

  const handleSelectF = (e) => {
    const item = JSON.parse(e);
    const branches = franchiseOptions.filter(
      (fr) => fr.franchise_id === item[1]
    )[0].branches;
    setBranchOptions(branches);
    setCurrBranch({});
    setCurrFran({
      franchise_id: item[1],
      franchise_name: item[0],
    });
  };

  const clearFilters = () => {
    setBranchName("All");
    setOrderType("All");
    setFilters({
      branch_id: "All",
      order_id: "",
      coupon: "",
      order_type: "",
      payment_method: "",
      max_price: "",
      min_price: "",
      start_date: "",
      end_date: "",
      customer_no: "",
      product_name: "",
    });
    console.log("filters", filters);
    setLoading(true);
    setDisplayableOrders([]);
    const queryString = "franchise_id=All&branch_id=All";
    console.log("queryString", queryString);
    setQueries(queryString);
    dispatch(fetchOrderList(1, queryString, records));
    dispatch(setOrderPagination(1));
    setOrderListVisible(true);
    // setFilters({
    //   branch_id: "",
    //   order_id: "",
    //   coupon: "",
    //   order_type: "",
    //   payment_method: "",
    //   max_price: "",
    //   min_price: "",
    //   start_date: "",
    //   end_date: "",
    //   customer_no: "",
    //   product_name: "",
    // });
    setCurrBranch({});
    setCurrFran({});
  };

  const showFilteredOrdersBySearch=(page)=>{
    // setDisplayableOrders([]);
    const queryString = getFilterQuery();
    setQueries(queryString);
    dispatch(fetchOrderList(page, queryString, records));
    dispatch(setOrderPagination(page));
    setOrderListVisible(true);
    setCurrBranch({});
    setCurrFran({});
  }
  const showFilteredOrders = (page) => {
    // setLoading(true);
    setDisplayableOrders([]);
    const queryString = getFilterQuery();
    setQueries(queryString);
    dispatch(fetchOrderList(page, queryString, records));
    dispatch(setOrderPagination(page));
    setOrderListVisible(true);
    // setFilters({
    //   branch_id: "",
    //   order_id: "",
    //   coupon: "",
    //   order_type: "",
    //   payment_method: "",
    //   max_price: "",
    //   min_price: "",
    //   start_date: "",
    //   end_date: "",
    //   customer_no: "",
    //   product_name: "",
    // });
    setCurrBranch({});
    setCurrFran({});
  };

  // const showFilteredCustomers = (page) => {
  //   const queryString = getFilterQuery();
  //   dispatch(fetchCustomerList(page, queryString));
  //   dispatch(setOrderPagination(page));
  //   setOrderListVisible(false);
  // };

  // console.log(branchName, "check");
  const searchBoxes = {
    branch_name: (
      <Select
        options={[
          { label: "All", value: "All" },
          ...branch?.data?.data.map((b) => ({
            label: b.branch_name,
            value: b.branch_id,
          })),
        ]}
        defaultValue={branchName}
        // value={branchName}
        onChange={(e) => {
          onChangeForSearchFields("branch_id", e);
        }}
      />
    ),
    order_id: (
      <input
        type="text"
        id="order_id"
        placeholder="search by Order Id "
        onChange={(e) => onChangeForSearchFields("order_id", e.target.value)}
      />
    ),
    order_items: (
      <input
        type="text"
        id="product_name"
        placeholder="Enter product names seperated by ,"
        value={filters.product_name}
        onChange={(e) =>
          onChangeForSearchFields("product_name", e.target.value)
        }
      />
    ),
    customer_no: (
      <input
        type="text"
        id="customer_no"
        placeholder="search by customer number"
        value={filters.customer_no}
        onChange={(e) => onChangeForSearchFields("customer_no", e.target.value)}
      />
    ),
    createdAt: (
      <span className="d-flex">
        <div>
          <div>Start Date</div>
          <input
            type="date"
            id="start_date"
            placeholder="start date"
            style={{ width: "7rem" }}
            onChange={(e) =>
              onChangeForSearchFields("start_date", e.target.value)
            }
          />
        </div>
        <div style={{ marginLeft: "1rem" }}>
          <div>End Date</div>
          <input
            type="date"
            id="end_date"
            placeholder="end date"
            style={{ width: "7rem" }}
            onChange={(e) =>
              onChangeForSearchFields("end_date", e.target.value)
            }
          />
        </div>
      </span>
    ),
    paid_price: (
      <span>
        <input
          type="number"
          id="min_price"
          placeholder="min price"
          style={{ width: "6rem" }}
          value={filters.min_price}
          onChange={(e) => onChangeForSearchFields("min_price", e.target.value)}
        />
        <input
          type="number"
          id="max_price"
          placeholder="max price"
          style={{ width: "6rem", marginLeft: "1rem" }}
          value={filters.max_price}
          onChange={(e) => onChangeForSearchFields("max_price", e.target.value)}
        />
      </span>
    ),
    payment_method: (
      <Select
        options={[
          { label: "All", value: "All" },
          { label: "wallet", value: "wallet" },
          { label: "wallet + card", value: "wallet plus card" },
          { label: "wallet + cash", value: "wallet plus cash" },
          { label: "card", value: "card" },
          { label: "card + cash", value: "card plus cash" },
          { label: "account", value: "account" },
          { label: "online", value: "online" },
        ]}
        onChange={(e) => onChangeForSearchFields("payment_method", e.value)}
      />
    ),
    order_type: (
      // <input
      //   type="text"
      //   id="order_type"
      //   placeholder="search by order type"
      //   value={filters.order_type}
      //   onChange={(e) => onChangeForSearchFields("order_type", e.target.value)}
      // />
      // change for dropdown
      <Select
        options={[
          { label: "All", value: "" },
          { label: "Dine In", value: "Dine In" },
          { label: "Take Away", value: "Take Away" },
          { label: "Delivery", value: "Delivery" },
          // { label: "", value: "" }
        ]}
        // defaultValue={ordertype}
        value={ordertype}
        onChange={(e) => {
          console.log(e);
          onChangeForSearchFields("order_type", e);
        }}
      />
    ),
    applied_coupons: (
      <input
        type="text"
        id="coupon"
        placeholder="search by coupon"
        value={filters.coupon}
        onChange={(e) => onChangeForSearchFields("coupon", e.target.value)}
      />
    ),
    action: "search",
  };

  const columns = [
    {
      dataField: "order_id",
      text: "Order ID",
      sort: false,
    },
    {
      dataField: "customer_no",
      text: "Customer No.",
      sort: true,
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: false,
    },
    {
      dataField: "total_items",
      text: "Total Items",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "paid_price",
      text: "Paid Price",
      sort: true,
    },
    {
      dataField: "sub_total",
      text: "Sub total",
      sort: true,
    },
    {
      dataField: "tax",
      text: "tax",
      sort: true,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "applied_coupons",
      text: "Applied Coupons",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "paid",
      text: "Paid",
      sort: true,
    },
    {
      dataField: "payment_id",
      text: "Payment ID",
      sort: true,
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      sort: true,
    },
    {
      dataField: "order_type",
      text: "Order Type",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Placed At",
      sort: true,
      // Perform a reverse sorting here
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const dateA = new Date(a);
        const dateB = new Date(b);

        if (order === "asc") {
          return dateA.getTime() - dateB.getTime();
        } else if (order === "desc") {
          return dateB.getTime() - dateA.getTime();
        }

        return 0;
      },
    },
    {
      dataField: "order_items",
      text: "Order Items",
      sort: true,
    },
    {
      dataField: "change",
      text: "Change",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: records,
    totalSize: orders?.data ? orders.data?.total_orders_count : 0, // replace later with size(customers),
    custom: true,
    page: pageNo,
    alwaysShowAllBtns: true,
    showTotal: true,

    bootstrap4: true, // Add this line
  };

  const getAllRows = () => {
    const rows = [...displayableOrders];
    rows.unshift(searchBoxes);
    return rows;
  };

  const onExportReport = async (queries) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_orders/export_customer_list/?${queries}`
      );

      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  if (props.viewPermission) {
    if (!loading) {
      return (
        <React.Fragment>
          <div className="page-content ">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex">
                        <Card.Title className="h4 mb-4 my-auto">
                          {orderListVisible
                            ? "Orders Datatable"
                            : "Customers Datatable"}
                        </Card.Title>
                      </div>
                      <div className="d-flex">
                        <DropdownButton
                          variant="light"
                          title={
                            currfranchise.franchise_name
                              ? currfranchise.franchise_name
                              : "Franchise"
                          }
                          id="dropdown-menu-align-right"
                          onSelect={handleSelectF}
                        >
                          {displayFranchises()}
                        </DropdownButton>

                        {/* <DropdownButton
                        variant="light"
                        title={
                          currbranch.branch_name
                            ? currbranch.branch_name
                            : "Branch"
                        }
                        id="dropdown-menu-align-right"
                        style={{ marginLeft: "1rem" }}
                        onSelect={handleSelectB}
                      >
                        {displayBranches()}
                      </DropdownButton> */}
                        <Button
                          className="btn btn-warning mb-3 ml-auto "
                          style={{ marginLeft: "auto", marginRight: "1rem" }}
                          onClick={() => showFilteredOrders(1)}
                        >
                          Show filtered orders
                        </Button>
                        <Button
                          className="btn btn-warning mb-3 ml-auto "
                          style={{ marginRight: "1rem" }}
                          onClick={() => clearFilters()}
                        >
                          Clear Filters
                        </Button>
                        <Button
                          className="btn btn-warning mb-3 "
                          onClick={() => setOrderListVisible(false)}
                          style={{ marginRight: "1rem" }}
                        >
                          Show Customer List
                        </Button>
                        {/* <Button
                        className="btn btn-warning mb-3 "
                        onClick={() => setAddCustomerToGrpModal(true)}
                      >
                        Add Customers to Group
                      </Button> */}
                        <Button
                          className="btn btn-warning mb-3"
                          onClick={() => onExportReport(queries)}
                        >
                          Export Customer List
                        </Button>
                        <AddCustomerToModal
                          show={addCustomerToGrpModal}
                          close={() => setAddCustomerToGrpModal(false)}
                          queryString={getFilterQuery()}
                        />
                      </div>
                      {orderListVisible ? (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="order_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="order_id"
                              columns={columns}
                              data={getAllRows()}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="order_id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={() => (
                                            <div className="text-center">
                                              No data found
                                            </div>
                                          )}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col xl={6}>
                                      <OrderPagination
                                        pageNum={Math.ceil(
                                          (orders.data
                                            ? orders.data.total_orders_count
                                            : 0) / records
                                        )}
                                        onPageChange={showFilteredOrders}
                                      />
                                    </Col>
                                    <Col className="d-flex">
                                      <div style={{ marginLeft: "auto" }}>
                                        <Select
                                          options={[
                                            { label: "10", value: "10" },
                                            { label: "100", value: "100" },
                                            { label: "500", value: "500" },
                                            { label: "1000", value: "1000" },
                                          ]}
                                          onChange={(e) => {
                                            dispatch(
                                              fetchOrderList(
                                                pageNo,
                                                getFilterQuery(),
                                                e.value
                                              )
                                            );
                                            setRecords(e.value);
                                          }}
                                          className="ml-auto"
                                          value={{
                                            label: records,
                                            value: records,
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      ) : (
                        <CustomerList
                          // showFilteredCustomers={showFilteredCustomers}
                          queryString={getFilterQuery()}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
        </React.Fragment>
      );
    } else return <Loader />;
  } else {
    return <Unauthorized />;
  }
};

export default AllOrders;
