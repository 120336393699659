import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getSalesByStaffReport } from "../../../store/actionCreators/Reports/StaffReportsAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import { CSVLink } from "react-csv";
import { fetchEmployees } from "../../../store/actionCreators/Employees/EmployeeAction";
import Async from "react-select/async";
import axios from "axios";
import { toast } from "react-toastify";
import ScrollButton from "../../scrolltotop/ScrollButton";
import StaffLogoutReportTable from "./StaffLogoutReportTable";
import Loader from "../../Loader/LoaderTable";

const StaffLogoutReport = (props) => {
  const dispatch = useDispatch();
  const sbsReport = useSelector((state) => state.sales_by_staff);
  const sbsdReport = useSelector((state) => state.sales_by_staff_daily);
  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);

  const [page, setPage] = useState(1);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [empArr, setEmpArr] = useState({
    label: "All",
    value: "All",
  });
  const [query, setQuery] = useState({
    employee_id: "All",
    branch_id: "All",
    employee: "",
    // status: "All",
    // start: "2021-01-19",
    // end: "2022-01-02",

    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [empSelected, setEmpSelected] = useState(
    sessionStorage.getItem("employee")
      ? JSON.parse(sessionStorage.getItem("employee"))
      : {
          value: "",
          label: "Search Employee",
        }
  );
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [durationSelected, setDurationSelected] = useState({
    label: "All",
    value: "All",
  });

  const [isDaily, setIsDaily] = useState(false);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    setProductData([]);
    dispatch(fetchEmployees(1, "All", "All", query.employee));
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [branch, employees]);

  useEffect(() => {
    if (
      sbsReport.data &&
      sbsReport.data.status === "success" &&
      durationSelected.value === "All" &&
      showReportClicked
    ) {
      setIsDaily(false);
      const productDataClone = sbsReport.data.data.map((e) => {
        return {
          ...e,
          // full_name: e.emplyees?.full_name,
          // paid_price: e.paid_price.toLocaleString(),
          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
        };
      });
      productDataClone.push(total);
      setProductData(productDataClone);
      setPage(1);
    }
  }, [sbsReport]);

  useEffect(() => {
    if (
      sbsdReport.data &&
      sbsdReport.data.status === "success" &&
      durationSelected.value !== "All" &&
      showReportClicked
    ) {
      setIsDaily(true);
      setProductData(
        sbsdReport.data.data.map((e) => ({
          ...e,
          // full_name: e.emplyees.full_name,
          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
        }))
      );
      setPage(1);
    }
  }, [sbsdReport]);

  const loadData = async (emp) => {
    console.log(emp);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((e) => ({
          // label: `${e?.full_name}`,
          // value: e?.employee_id,
          label: ` ${e?.full_name}`,
          value: e?.full_name,
          branch_id: e?.branch_id,
          employee_id: e?.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };
  const loadDataemplo = () => {
    return (
      !!employees?.data?.data &&
      employees?.data?.data.map((item, index) => {
        return {
          label: item.full_name,
          value: item.full_name,
          branch_id: item.branch_id,
          employee_id: item.employee_id,
        };
      })
    );
  };

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }

    if (employees.data && employees.data.data) {
      const empArrClone = employees.data.data.map((b) => ({
        label: b?.full_name,
        value: b?.employee_id,
      }));
      empArrClone.unshift({ label: "All", value: "All" });
      setEmpArr(empArrClone);
    }
  };

  const onShowReport = () => {
    if (!empSelected.employee_id) {
      // alert("PLEASE SELECT EMPLOYEE NAME");
      toast.error(`PLEASE SELECT EMPLOYEE NAME`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setShowReportClicked(true);
    }
  };

  const columnsDaily = [
    {
      dataField: "day",
      text: "Day",
      sort: true,
    },
    {
      dataField: "staff",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "order_count",
      text: "Order Count",
      sort: true,
    },
    {
      dataField: "sales_total",
      text: "Sales Total",
      sort: true,
    },
  ];

  // const columns = [
  //   {
  //     dataField: "branch",
  //     text: "Branch",
  //     sort: true,
  //   },
  //   {
  //     dataField: "user_name",
  //     text: "Username",
  //     sort: true,
  //   },
  //   {
  //     dataField: "log_in",
  //     text: "Log In",
  //     sort: true,
  //   },
  //   {
  //     dataField: "log_out",
  //     text: "Log Out",
  //     sort: true,
  //   },
  //   {
  //     dataField: "order_count",
  //     text: "Order Count",
  //     sort: true,
  //   },
  //   {
  //     dataField: "cancelled_order_count",
  //     text: "Cancelled Order Count",
  //     sort: true,
  //   },
  //   {
  //     dataField: "final_sales",
  //     text: "Final Sales",
  //     sort: true,
  //   },
  //   {
  //     dataField: "final_cash",
  //     text: "Final Cash",
  //     sort: true,
  //   },
  //   {
  //     dataField: "petty_cash",
  //     text: "Petty Cash",
  //     sort: true,
  //   },
  //   {
  //     dataField: "wallet_credits",
  //     text: "Wallet Credits",
  //     sort: true,
  //   },
  //   {
  //     dataField: "wallet_sales",
  //     text: "Wallet Sales",
  //     sort: true,
  //   },
  //   {
  //     dataField: "delivery_sales",
  //     text: "Delivery sales",
  //     sort: true,
  //   },
  //   {
  //     dataField: "delivery_charges",
  //     text: "Delivery charges",
  //     sort: true,
  //   },
  //   {
  //     dataField: "digital_sales",
  //     text: "Digital Sales",
  //     sort: true,
  //   },
  //   {
  //     dataField: "cash_sales",
  //     text: "Cash Sales",
  //     sort: true,
  //   },
  //   {
  //     dataField: "order_revenue",
  //     text: "Order Revenue",
  //     sort: true,
  //   },
  //   {
  //     dataField: "average_time_per_order",
  //     text: "Average Time per order",
  //     sort: true,
  //   },
  //   {
  //     dataField: "average_ticket_size",
  //     text: "Average Ticket Size",
  //     sort: true,
  //   },
  // ];

  const total = {
    not_include: true,
    full_name: <strong>Total</strong>,
    paid_price: (
      <strong>
        {sbsReport.data
          ? Number.parseFloat(sbsReport.data.total_sales).toLocaleString()
          : ""}
      </strong>
    ),
    order_count: (
      <strong>{sbsReport.data ? sbsReport.data.total_order_count : ""}</strong>
    ),
  };

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  return (
    // <Container
    //   fluid
    //   className={props.sideToggle === true ? "closeDash" : "openDash"}
    // style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    // >
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">STAFF LOGOUT REPORT</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body style={{ height: "200px" }}>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    {/* <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Order status:</td>
                      <td className="">
                        <Select
                          options={orderStatusOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr> */}
                    {/* <tr> */}
                    {/* <label>Employee: </label> */}
                    {/* <td className=""> */}
                    {/* <Async
                          defaultOptions={[{ label: "All", value: "All" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        /> */}
                    {/* <select
                      className="form-control form-select form-select-sm p-2"
                      // value={filters.branch_id}
                      // onChange={(e) => {
                      onChange={(e) =>
                        setQuery({ ...query, employee: e.value })
                      }
                      // }}
                    >
                      <option value="">All</option>
                      {loadDataemplo()}
                    </select> */}
                    {/* </td> */}
                    {/* </tr> */}
                    <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={loadDataemplo()}
                          cacheOptions={loadDataemplo()}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => {
                            sessionStorage.setItem(
                              "employee",
                              JSON.stringify(e)
                            );
                            setEmpSelected(e);
                          }}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Duration:</td>
                      <td className="">
                        <Select
                          options={[
                            { label: "All", value: "All" },
                            { label: "Daily", value: "Daily" },
                          ]}
                          value={durationSelected}
                          onChange={(e) => setDurationSelected(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "csv", value: "csv" }]}
                value={{ label: "csv", value: "csv" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
              >
                <CSVLink
                  data={
                    !isDaily
                      ? productData.map((p) => {
                          if (!p.not_include)
                            return {
                              Staff: p.full_name,

                              Sales_Total: p.paid_price,

                              Order_Count: p.order_count,
                            };
                          else
                            return {
                              Staff: "Total",
                              Sales_Total: sbsReport?.data?.total_sales,
                              Order_Count: sbsReport?.data?.total_order_count,
                            };
                        })
                      : productData.map((p) => ({
                          Day: new Date(p.date).toDateString(),
                          Staff: p.full_name,
                          Order_Count: p.order_count,
                          Sales_Total: p.paid_price,
                        }))
                  }
                  filename={"report.csv"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Export
                </CSVLink>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={isDaily ? columnsDaily : columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={isDaily ? columnsDaily : columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <SBSPagination pageNum={Math.ceil(page)} />
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        ""
      )} */}
      {showReportClicked || !!empSelected.employee_id ? (
        <StaffLogoutReportTable empSelected={empSelected} />
      ) : (
        ""
      )}
      <ScrollButton />
    </React.Fragment>
    // </Container>
  );
};

export default StaffLogoutReport;
