import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Async from "react-select/async";
// import Unauthorized from "../../unauthorized";
// import axios from "axios";
// import Async from "react-select/async";
// import { getGroupList } from "../../../store/actionCreators/Groups/GroupsAction";

const EditLastForm = ({
  step,
  setStep,
  data,

  onChangeForFields,

  handleFinalSubmit,
  displayBranch,
  loadData,
  setEmployees,
  isLoading,
  employees,
}) => {
  const customerRoleData = useSelector((state) => state.customer_roles);

  const customerRolesList = customerRoleData?.data?.all_customer_roles || [];

  const couponForOptions = [
    { label: "All", value: "All" },
    { label: "POS", value: "POS" },
    { label: "WEB", value: "WEB" },
    { label: "APP", value: "APP" },
  ];

  const paymentOptions = [
    { label: "All", value: "All" },
    { label: "Cash", value: "cash" },
    { label: "online", value: "online" },
    { label: "Wallet", value: "wallet" },
    { label: "Card", value: "card" },
    { label: "card + cash", value: "card + cash" },
    { label: "wallet + cash", value: "wallet + cash" },
    { label: "wallet + card", value: "wallet + card" },
  ];

  
  const customerRolesOptions = [{ label: "All", value: "All" }].concat(
    customerRolesList.map((item, i) => {
      return {
        label: item.customer_type,
        value: item.customer_type,
      };
    })
  );

  return (
    <div className="page-content ">
      <div className="mb-3">
        <strong>Products/Addons Discount</strong>
      </div>
      <form onSubmit={handleFinalSubmit}>
        <Row className="mb-2">
          <Col>
            <label>Title</label>
            <input
              required
              id="title"
              type="text"
              className="form-control"
              value={data.title}
              onChange={(e) => onChangeForFields("title", e.target.value)}
            />
          </Col>
          <Col>
            <label>Description</label>
            <input
              onChange={(e) => onChangeForFields("description", e.target.value)}
              required
              type="text"
              className="form-control"
              value={data.description}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <label>Coupon Code</label>
            <input
              onChange={(e) => onChangeForFields("code", e.target.value)}
              required
              type="text"
              className="form-control"
              value={data.code}
            />
          </Col>
          <Col>
            <label>Minimum Cart Value</label>
            <input
              onChange={(e) =>
                onChangeForFields("min_cart_value", e.target.value)
              }
              type="number"
              className="form-control"
              value={data.min_cart_value}
            />
          </Col>
          <Col>
            <label>Discount Value</label>
            <input
              onChange={(e) =>
                onChangeForFields("discount_value", e.target.value)
              }
              type="number"
              className="form-control"
              value={data.discount_value}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label>Start Date</label>
            <input
              onChange={(e) => onChangeForFields("start", e.target.value)}
              type="date"
              className="form-control"
              value={data.start}
            />
          </Col>
          <Col>
            <label>End Date</label>
            <input
              onChange={(e) => onChangeForFields("end", e.target.value)}
              type="date"
              className="form-control"
              value={data.end}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="m-2">{displayBranch()}</Col>
          <Col>
            <label>Platform </label>
            <Select
              onChange={(e) => {
                onChangeForFields("platform", e.value);
              }}
              options={couponForOptions}
              value={{ label: data.platform, value: data.platform }}
            />
          </Col>
          <Col>
            <label>Payment Type</label>
            <Select
              onChange={(e) => onChangeForFields("payment_type", e.value)}
              options={paymentOptions}
              value={{ label: data.payment_type, value: data.payment_type }}
            />
          </Col>{" "}
        </Row>
        <Row className="mb-2">
          <Col>
            {" "}
            <div className="mb-3 p-2">
              <label className="form-label">Max Discount Valid:</label>
              <div className="d-flex">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value={true}
                    onChange={(e) =>
                      onChangeForFields("max_discount_valid", true)
                    }
                    id="flexRadioDefault1"
                    checked={data.max_discount_valid === true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    True
                  </label>
                </div>
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value={false}
                    onChange={(e) =>
                      onChangeForFields("max_discount_valid", false)
                    }
                    id="flexRadioDefault2"
                    checked={data.max_discount_valid === false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    False
                  </label>
                </div>
              </div>
            </div>
          </Col>
          {data.max_discount_valid === true && (
            <Col>
              <label>Max Discount Value</label>
              <input
                onChange={(e) =>
                  onChangeForFields("max_discount_value", e.target.value)
                }
                type="number"
                className="form-control"
                value={data.max_discount_value}
              />
            </Col>
          )}
        </Row>

        <Row className="mb-2">
          <Col>
            {" "}
            <div className="mb-3 p-2">
              <label className="form-label">Max use Valid:</label>
              <div className="d-flex">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault3"
                    value={true}
                    onChange={(e) => onChangeForFields("max_use_valid", true)}
                    id="flexRadioDefault001"
                    checked={data.max_use_valid === true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault001"
                  >
                    True
                  </label>
                </div>
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault3"
                    value={false}
                    onChange={(e) => onChangeForFields("max_use_valid", false)}
                    id="flexRadioDefault00"
                    checked={data.max_use_valid === false}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault00"
                  >
                    False
                  </label>
                </div>
              </div>
            </div>
          </Col>
          {data.max_use_valid === true && (
            <Col>
              <label>Max Use Value:</label>
              <input
                onChange={(e) =>
                  onChangeForFields("max_use_value", e.target.value)
                }
                type="number"
                className="form-control"
                value={data.max_use_value}
              />
            </Col>
          )}

          <Col>
            <label>Min Order For Coupon</label>
            <input
              onChange={(e) =>
                onChangeForFields("min_order_for_coup", e.target.value)
              }
              type="number"
              className="form-control"
              value={data.min_order_for_coup}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          {data?.customer_type === "Groups" ? (
            <Col>
              <label>Select Customer Roles</label>
              <Select
                value={{
                  label: data?.customer_role,
                  value: data?.customer_role,
                }}
                options={customerRolesOptions}
                onChange={(e) => onChangeForFields("customer_role", e.value)}
              />
            </Col>
          ) : (
            ""
          )}
          <Col>
            <label>Employees:</label>
            <div
              className=""
              // style={{ width: "20rem" }}
            >
              <Async
                defaultOptions={[]}
                cacheOptions={[{ label: "1234", value: "1234" }]}
                isLoading={isLoading}
                loadOptions={(e) => loadData(e)}
                onChange={(e) => setEmployees(e)}
                isMulti={true}
                value={employees}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <label>Combine:</label>
            <Select
              value={{ label: data.combine.toString(), value: data.combine }}
              options={[
                { label: "True", value: true },
                { label: "False", value: false },
              ]}
              onChange={(e) => onChangeForFields("combine", e.value)}
            />
          </Col>
          <Col>
            <label>Membership Apply:</label>
            <Select
              value={{
                label: data.memb_apply.toString(),
                value: data.memb_apply,
              }}
              options={[
                { label: "True", value: true },
                { label: "False", value: false },
              ]}
              onChange={(e) => onChangeForFields("memb_apply", e.value)}
            />
          </Col>
          <Col>
            <label>Active:</label>
            <Select
              value={{ label: data.active.toString(), value: data.active }}
              options={[
                { label: "True", value: true },
                { label: "False", value: false },
              ]}
              onChange={(e) => onChangeForFields("active", e.value)}
            />
          </Col>
        </Row>

        <Button
          className=" mx-2"
          type="warning"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default EditLastForm;
