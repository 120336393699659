import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../unauthorized";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../css/customer/customerTable.css";
import CareerPagination from "./CareerPagination";
import BootstrapTable from "react-bootstrap-table-next";
import { fetchCareer, fetchCareerBySearch } from "./../../store/actionCreators/Employees/EmployeeAction";
import { NavLink } from "react-router-dom";

const Career = (props) => {
  const dispatch = useDispatch();

  const page = useSelector((state) => state.career_page);
  const career = useSelector((state) => state.career);
  // const [sizePerPage, setSizePerPage] = useState(10);
  const [productData, setProductData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    dispatch(fetchCareer(page));
  }, [page]);

  useEffect(() => {
    dispatch(fetchCareerBySearch(page,search));
  }, [search]);


  useEffect(() => {
    if (career.data && career.data.status === "success")
      setProductData(career.data.result);
    setTotalCount(career?.data?.total_count);
  }, [career]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "phone",
      text: "Contact",
      sort: false,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "position",
      text: "Position",
      sort: false,
    },
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        style={{
          textAlign: "start",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Button
          onClick={() => openInNewTab(row.resume)}
          className="btn btn-warning"
        >
          Resume
        </Button>
      </div>
    );
  }

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: productData?.length,
    totalSize: totalCount,
    custom: true,
  };
  console.log({ totalCount });
  const { SearchBar } = Search;

  if (props.editPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <React.Fragment>
          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <Card.Title className="h4 mb-2 ">
                      Employee Datatable{" "}
                    </Card.Title>

                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="employee_id"
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="employee_id"
                          
                          columns={columns}
                          data={productData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                          setSearch(e.target.value);
                                          console.log("search By vipul", setSearch);;
                                        }}
                                      />

                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="employee_id"
                                      responsive
                                      //   rowEvents={{
                                      //     onClick: function (e, row) {
                                      //       onClickFunction(row);
                                      //       navigate("/employee/individual");
                                      //     },
                                      //   }}
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <CareerPagination
                                  pageNum={Math.ceil(
                                    career.data.result
                                      ? career?.data?.total_count / 20
                                      : 1
                                  )}
                                />
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      </Container>
    );
  else return <Unauthorized />;
};

export default Career;
